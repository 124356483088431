import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AccountAvatar from "components/Avatar";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { theme } from "config/theme";
import { User } from "api/generated";
import { useTranslation } from "react-i18next";

const MainInfo = ({ firstName, lastName, middleName, phone, email, vehicles, uuid }: User) => {
  const { t } = useTranslation();
  // Подходит только для номеров телефона где код состоит из 1 цифры
  const styledPhone =
    phone && phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5");

  return (
    <>
      <Box marginRight='30px' padding='1rem 0 0 1rem'>
        <AccountAvatar name={`${firstName} ${lastName}`} size={120} fontSize='3.75rem' />
      </Box>
      <Grid container spacing={2} width='calc(100% - 150px)' padding='1rem 1rem 0 0'>
        <Grid item xs={12}>
          <Typography variant='h4'>{`${firstName} ${middleName} ${lastName}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Box paddingBottom='1rem'>
            <Typography variant='overline' fontWeight='bold' color={theme.palette.text.secondary}>
              {t("pages.Accounts.item.phone")}:
            </Typography>
            {(phone && (
              <Typography variant='body1' sx={{ lineHeight: 2 }}>
                {styledPhone}
              </Typography>
            )) || (
              <Typography variant='body1' sx={{ lineHeight: 2 }} data-testid='noPhone'>
                {t("pages.Accounts.item.no_data")}
              </Typography>
            )}
          </Box>
          <Box paddingBottom='1rem'>
            <Typography variant='overline' fontWeight='bold' color={theme.palette.text.secondary}>
              email:{" "}
              <Link
                href={`mailto:${email}`}
                display='block'
                textTransform='lowercase'
                fontSize='1rem'
                lineHeight='1.25'
              >
                {email || t("pages.Accounts.item.no_data")}
              </Link>
            </Typography>
          </Box>
          <Box paddingBottom='1rem'>
            <Typography variant='overline' fontWeight='bold' color={theme.palette.text.secondary}>
              UUID:
            </Typography>
            <Typography variant='body1' sx={{ lineHeight: 2 }}>
              {uuid?.value || t("pages.Accounts.item.no_data")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant='overline' fontWeight='bold' color={theme.palette.text.secondary}>
              {t("pages.Accounts.item.vehicle")}:
            </Typography>
            {vehicles?.length ? (
              <List disablePadding>
                {vehicles.map(({ vrp }) => (
                  <ListItem key={vrp} disablePadding>
                    <ListItemIcon>
                      <DirectionsCarIcon />
                    </ListItemIcon>
                    <ListItemText primary={vrp} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant='body1' sx={{ lineHeight: 2 }} data-testid='noVehicles'>
                {t("pages.Accounts.item.no_data")}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default MainInfo;
