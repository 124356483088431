import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Complex } from "hooks/queries/complexes";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getApiCertificateExpiry,
  parseApiDate,
  parsePztCameraToApi,
  parseSelectToApi
} from "../utils";
import { ComplexForm, PztCamera } from "./types";
import IdInput from "./IdInput";
import ModelInput from "./ModelInput";
import ComplexTypeSelect from "./ComplexTypeSelect";
import FixationLocationSelect from "./FixationLocationSelect";
import PztCameraSelect from "./PztCameraSelect";
import CertificateNumberInput from "./CertificateNumberInput";
import CertificateExpiryDatePicker from "./CertificateExpiryDatePicker";
import { createComplexDialogTheme } from "./theme";

export interface ComplexDialogProps {
  dialogTitle: string;
  submitTitle: string;
  complex?: Complex;
  open: boolean;
  onClose?: VoidFunction;
  TransitionProps?: TransitionProps;
  onSubmit: (complex: Complex) => void;
  isLoading: boolean;
}

const getFormPztCameraFromApi = (pztCamera?: boolean): PztCamera | "" => {
  switch (pztCamera) {
    case true:
      return PztCamera.True;

    case false:
      return PztCamera.False;

    default:
      return "";
  }
};

const ComplexDialog = ({
  dialogTitle,
  submitTitle,
  open,
  complex,
  onClose,
  TransitionProps,
  onSubmit,
  isLoading
}: ComplexDialogProps) => {
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, watch, clearErrors } = useForm<ComplexForm>({
    defaultValues: {
      uuid: complex?.uuid,
      model: complex?.model || "",
      type: complex?.type || "",
      certificateExpiry: complex?.certificateExpiry
        ? parseApiDate(complex.certificateExpiry)
        : null,
      certificateNumber: complex?.certificateNumber || "",
      fixationLocation: complex?.fixationLocation || "",
      pztCamera: getFormPztCameraFromApi(complex?.pztCamera)
    }
  });

  const handleSubmitValid: SubmitHandler<ComplexForm> = ({
    certificateExpiry,
    certificateNumber,
    fixationLocation,
    model,
    pztCamera,
    type,
    uuid
  }) => {
    onSubmit({
      certificateExpiry: getApiCertificateExpiry(certificateExpiry),
      certificateNumber,
      fixationLocation: parseSelectToApi(fixationLocation),
      model,
      pztCamera: parsePztCameraToApi(pztCamera),
      type: parseSelectToApi(type),
      uuid
    });
  };

  return (
    <ThemeProvider theme={createComplexDialogTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionProps={TransitionProps}
        PaperProps={{ sx: { width: "100%", maxWidth: "600px" } }}
      >
        <form onSubmit={handleSubmit(handleSubmitValid)}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            {complex?.uuid && <IdInput fullWidth control={control} sx={{ mt: 2 }} />}

            <ModelInput fullWidth control={control} sx={{ mt: 2 }} />

            <ComplexTypeSelect
              fullWidth
              control={control}
              setValue={setValue}
              clearErrors={clearErrors}
              sx={{ mt: 2 }}
            />

            <FixationLocationSelect fullWidth control={control} watch={watch} sx={{ mt: 2 }} />

            <PztCameraSelect fullWidth control={control} watch={watch} sx={{ mt: 2 }} />

            <CertificateNumberInput fullWidth control={control} sx={{ mt: 2 }} />

            <CertificateExpiryDatePicker fullWidth control={control} sx={{ mt: 2 }} />
          </DialogContent>

          <DialogActions>
            <Button variant='outlined' onClick={onClose}>
              {t("common.cancelation")}
            </Button>

            <Button variant='contained' type='submit'>
              {submitTitle}
            </Button>
          </DialogActions>
        </form>

        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      </Dialog>
    </ThemeProvider>
  );
};

export default ComplexDialog;
