import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { COMPLEXES_QUERY_KEY } from "./useComplexesQuery";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";
import { Complex } from "./types";
import { requests } from "api";
import { CreateComplexRequest } from "api/generated";

const mapComplexToAPI = (complex: Complex): CreateComplexRequest => ({
  complexCertificateMetrology: complex.certificateNumber,
  complexExpirationDate: complex.certificateExpiry,
  complexModel: complex.model,
  complexType: complex.type,
  fix: complex.fixationLocation,
  pzt: complex.pztCamera
});

const useAddComplexMutation = () => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (complex: Complex) => requests.adminGatewayCreateComplex(mapComplexToAPI(complex)),
    onSuccess() {
      openSuccessAlert(t("pages.Complexes.complex_added"));
      queryClient.invalidateQueries([COMPLEXES_QUERY_KEY]);
    },
    onError() {
      showErrors({
        defaultMessage: t("pages.Complexes.could_not_add_complex")
      });
    }
  });
};

export default useAddComplexMutation;
