import { Control, UseFormWatch } from "react-hook-form";
import { ComplexForm, PztCamera } from "./types";
import { FormControl, MenuItem, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import Select from "ui-kit/form/Select";
import { ComplexType } from "hooks/queries/complexes";

interface PztCameraSelectProps {
  control: Control<ComplexForm>;
  fullWidth?: boolean;
  sx?: SxProps;
  watch: UseFormWatch<ComplexForm>;
}

const PztCameraSelect = ({ control, fullWidth, watch, sx }: PztCameraSelectProps) => {
  const { t } = useTranslation();

  const complexType = watch("type");
  const isMobile = complexType === ComplexType.Mobile;

  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      <Select
        control={control}
        name='pztCamera'
        label={t("pages.Complexes.labels.pzt_camera")}
        disabled={isMobile}
        rules={{
          required: {
            value: !isMobile,
            message: t("common.input_rules.required")
          }
        }}
      >
        <MenuItem value={PztCamera.True}>{t("pages.Complexes.pzt_cameras.true")}</MenuItem>
        <MenuItem value={PztCamera.False}>{t("pages.Complexes.pzt_cameras.false")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PztCameraSelect;
