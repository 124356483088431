import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface SendIllegalSessionDialogProps {
  open: boolean;
  onSend?: MouseEventHandler<HTMLButtonElement>;
  onClose?: VoidFunction;
  TransitionProps?: TransitionProps;
}

const SendIllegalSessionDialog = ({
  open,
  onSend,
  onClose,
  TransitionProps
}: SendIllegalSessionDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionProps={TransitionProps}
      onClose={onClose}
      PaperProps={{ sx: { width: "100%", maxWidth: "500px" } }}
    >
      <DialogContent>
        <Typography variant='h6' component='p' textAlign='center'>
          {t("pages.IllegalSessions.confirm_send_question")}
        </Typography>

        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          gap='16px'
          flexWrap='wrap'
          marginTop={2}
        >
          <Button variant='contained' onClick={onSend}>
            {t("pages.IllegalSessions.send")}
          </Button>

          <Button variant='outlined' onClick={onClose}>
            {t("common.cancel")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SendIllegalSessionDialog;
