import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { COMPLEXES_QUERY_KEY } from "./useComplexesQuery";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";
import { Complex } from "./types";
import { requests } from "api";
import { UpdateComplexRequest } from "api/generated";

const mapComplexToAPI = (complex: Complex): UpdateComplexRequest => ({
  complex: {
    complexId: complex.uuid,
    complexExpirationDate: complex.certificateExpiry,
    complexModel: complex.model,
    complexType: complex.type,
    fix: complex.fixationLocation,
    pzt: complex.pztCamera,
    complexCertificateMetrology: complex.certificateNumber
  }
});

const useEditComplexMutation = () => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (complex: Complex) => requests.adminGatewayUpdateComplex(mapComplexToAPI(complex)),
    onSuccess() {
      openSuccessAlert(t("pages.Complexes.complex_edited"));
      queryClient.invalidateQueries([COMPLEXES_QUERY_KEY]);
    },
    onError() {
      showErrors({
        defaultMessage: t("pages.Complexes.could_not_edit_complex")
      });
    }
  });
};

export default useEditComplexMutation;
