import { Box, Button, CircularProgress } from "@mui/material";
import SearchBar from "./SearchBar";
import { useForm } from "react-hook-form";
import { useState } from "react";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import { Complex, ComplexesListParams, useComplexesQuery } from "hooks/queries/complexes";
import ComplexesTableContainer from "./ComplexesTableContainer";
import AddComplexDialog from "../AddComplexDialog";
import EditComplexDialog from "../EditComplexDialog";
import { useTranslation } from "react-i18next";
import DeleteComplexDialog from "../DeleteComplexDialog";
import { useAppAbility } from "context/AbilityContext";

interface AddComplexDialogState {
  open: boolean;
  type: "add";
}

interface EditComplexDialogState {
  open: boolean;
  complex: Complex;
  type: "edit";
}

interface DeleteComplexDialogState {
  open: boolean;
  complex: Complex;
  type: "delete";
}

const ComplexesList = () => {
  const { t } = useTranslation();
  const { can } = useAppAbility();

  const canCreate = can("create", "Complexes");
  const canUpdate = can("update", "Complexes");
  const canDelete = can("delete", "Complexes");

  const { control, setValue, watch } = useForm<ComplexesListParams>({
    defaultValues: {
      q: "",
      limit: 20,
      offset: 0
    }
  });

  const [query, setQuery] = useState<string>("");

  const doSearch = useDebouncedCallback((value: string) => {
    setQuery(value);
    setValue("offset", 0);
  }, 1000);

  const handleSearch = (value: string) => {
    setValue("q", value);
    doSearch(value);
  };

  const limit = watch("limit") || 0;
  const offset = watch("offset") || 0;

  const { data, isLoading } = useComplexesQuery({
    q: query,
    limit,
    offset
  });

  const [complexDialog, setComplexDialog] = useState<
    AddComplexDialogState | EditComplexDialogState | DeleteComplexDialogState | null
  >(null);

  const handleCloseComplexDialog = () =>
    setComplexDialog(complexDialog && { ...complexDialog, open: false });

  const handleEditComplex = (complex: Complex) =>
    setComplexDialog({
      complex,
      open: true,
      type: "edit"
    });

  const handleAddComplex = () =>
    setComplexDialog({
      open: true,
      type: "add"
    });

  const handleDeleteComplex = (complex: Complex) =>
    setComplexDialog({
      complex,
      open: true,
      type: "delete"
    });

  return (
    <Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexWrap='wrap'
        marginBottom={2}
      >
        <SearchBar control={control} onSearch={handleSearch} />
        <Button variant='contained' onClick={handleAddComplex}>
          {t("pages.Complexes.add_complex")}
        </Button>
      </Box>

      {isLoading ? (
        <CircularProgress size={26} />
      ) : (
        <ComplexesTableContainer
          rows={data?.complexes}
          total={data?.total ?? 0}
          control={control}
          setValue={setValue}
          onEdit={handleEditComplex}
          onDelete={handleDeleteComplex}
        />
      )}

      {complexDialog?.type === "add" && canCreate && (
        <AddComplexDialog
          open={complexDialog.open}
          onClose={handleCloseComplexDialog}
          TransitionProps={{ onExited: () => setComplexDialog(null) }}
        />
      )}

      {complexDialog?.type === "edit" && canUpdate && (
        <EditComplexDialog
          open={complexDialog.open}
          complex={complexDialog.complex}
          onClose={handleCloseComplexDialog}
          TransitionProps={{ onExited: () => setComplexDialog(null) }}
        />
      )}

      {complexDialog?.type === "delete" && canDelete && (
        <DeleteComplexDialog
          open={complexDialog.open}
          complex={complexDialog.complex}
          onClose={handleCloseComplexDialog}
          TransitionProps={{ onExited: () => setComplexDialog(null) }}
        />
      )}
    </Box>
  );
};

export default ComplexesList;
