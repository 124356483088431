import { useTranslation } from "react-i18next";
import ComplexDialog, { ComplexDialogProps } from "../ComplexDialog";
import { Complex, useAddComplexMutation } from "hooks/queries/complexes";

type AddComplexDialog = Pick<ComplexDialogProps, "open" | "onClose" | "TransitionProps">;

const AddComplexDialog = ({ onClose, ...props }: AddComplexDialog) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useAddComplexMutation();

  const handleSubmit = (complex: Complex) => {
    mutate(complex, {
      onSuccess: () => {
        onClose?.();
      }
    });
  };

  return (
    <ComplexDialog
      {...props}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogTitle={t("pages.Complexes.complex_dialog.add_complex_title")}
      submitTitle={t("common.add")}
    />
  );
};

export default AddComplexDialog;
