import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { COMPLEXES_QUERY_KEY } from "./useComplexesQuery";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";
import { Complex } from "./types";
import { requests } from "api";

const deleteComplex = (complex: Complex) => {
  if (!complex.uuid) {
    throw new Error("complexId is undefined");
  }

  return requests.adminGatewayDeleteComplex(complex.uuid);
};

const useDeleteComplexMutation = () => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteComplex,
    onSuccess() {
      openSuccessAlert(t("pages.Complexes.complex_deleted"));
      queryClient.invalidateQueries([COMPLEXES_QUERY_KEY]);
    },
    onError() {
      showErrors({
        defaultMessage: t("pages.Complexes.could_not_delete_complex")
      });
    }
  });
};

export default useDeleteComplexMutation;
