import { useQuery } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";
import { requests } from "api";

export const ILLEGAL_SESSION_DETAILS_QUERY_KEY = "illegal_sessions_details";

const useIllegalSessionDetailsQuery = (uuid: string) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery(
    [ILLEGAL_SESSION_DETAILS_QUERY_KEY, uuid],
    () => requests.adminGatewayGetIllegalSession(uuid),
    {
      onError() {
        openErrorAlert(t("pages.IllegalSessions.could_not_load_illegal_session_details"));
      },
      refetchOnWindowFocus: false
    }
  );
};

export default useIllegalSessionDetailsQuery;
