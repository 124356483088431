import { useTranslation } from "react-i18next";
import ComplexDialog, { ComplexDialogProps } from "../ComplexDialog";
import { Complex, useEditComplexMutation } from "hooks/queries/complexes";

type EditComplexDialog = Pick<
  ComplexDialogProps,
  "open" | "onClose" | "complex" | "TransitionProps"
>;

const EditComplexDialog = ({ onClose, ...props }: EditComplexDialog) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useEditComplexMutation();

  const handleSubmit = (complex: Complex) => {
    mutate(complex, {
      onSuccess: () => {
        onClose?.();
      }
    });
  };

  return (
    <ComplexDialog
      {...props}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogTitle={t("pages.Complexes.complex_dialog.edit_complex_title")}
      submitTitle={t("common.save")}
    />
  );
};

export default EditComplexDialog;
