import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";

const StyledSearchInput = ({ InputProps, ...props }: TextFieldProps) => {
  const { t } = useTranslation();

  return (
    <TextField
      label={t("common.search")}
      variant='standard'
      fullWidth
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>
        ),
        ...InputProps
      }}
    />
  );
};

export default StyledSearchInput;
