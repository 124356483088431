import { Box, Paper, TableContainer, Typography } from "@mui/material";
import Pagination from "components/Pagination";
import { IllegalSession, GetIllegalSessionsRequest } from "hooks/queries/illegal-sessions";
import { Control, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import IllegalSessionsTable from "./IllegalSessionsTable";

interface IllegalSessionsTableContainerProps {
  rows?: IllegalSession[];
  control: Control<GetIllegalSessionsRequest>;
  total: number;
  setValue: UseFormSetValue<GetIllegalSessionsRequest>;
  onSend?: (illegalSession: IllegalSession) => void;
  onRowClick?: (illegalSession: IllegalSession) => void;
}

const IllegalSessionsTableContainer = ({
  rows,
  control,
  total,
  setValue,
  onSend,
  onRowClick
}: IllegalSessionsTableContainerProps) => {
  const { t } = useTranslation();

  if (!rows || rows.length === 0) {
    return (
      <Typography variant='h5' component='p'>
        {t("pages.IllegalSessions.no_data")}
      </Typography>
    );
  }

  return (
    <>
      <Box display='grid'>
        <TableContainer
          component={Paper}
          sx={{ ".MuiTableCell-root.MuiTableCell-head": { fontWeight: "bold" } }}
        >
          <IllegalSessionsTable rows={rows} onSend={onSend} onRowClick={onRowClick} />
        </TableContainer>
      </Box>

      <Pagination control={control} total={total} setValue={setValue} />
    </>
  );
};

export default IllegalSessionsTableContainer;
