import { Control, RegisterOptions, UseFormClearErrors, UseFormSetValue } from "react-hook-form";
import { ComplexForm } from "./types";
import { FormControl, MenuItem, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import Select from "ui-kit/form/Select";
import { ComplexType } from "hooks/queries/complexes";

interface ComplexTypeSelectProps {
  control: Control<ComplexForm>;
  setValue: UseFormSetValue<ComplexForm>;
  clearErrors: UseFormClearErrors<ComplexForm>;
  fullWidth?: boolean;
  sx?: SxProps;
}

const ComplexTypeSelect = ({
  control,
  fullWidth,
  setValue,
  clearErrors,
  sx
}: ComplexTypeSelectProps) => {
  const { t } = useTranslation();

  const handleChange: RegisterOptions["onChange"] = (e) => {
    const value = e?.target?.value ?? "";

    if (value === ComplexType.Mobile) {
      setValue("fixationLocation", "");
      clearErrors("fixationLocation");
      setValue("pztCamera", "");
      clearErrors("pztCamera");
    }
  };

  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      <Select
        control={control}
        name='type'
        label={t("pages.Complexes.labels.type")}
        rules={{
          required: {
            value: true,
            message: t("common.input_rules.required")
          },
          onChange: handleChange
        }}
      >
        <MenuItem value={ComplexType.Stationary}>
          {t("pages.Complexes.complex_types.stationary")}
        </MenuItem>
        <MenuItem value={ComplexType.Mobile}>{t("pages.Complexes.complex_types.mobile")}</MenuItem>
      </Select>
    </FormControl>
  );
};

export default ComplexTypeSelect;
