export interface ComplexesListParams {
  q?: string;
  limit?: number;
  offset?: number;
}

export enum ComplexType {
  Mobile = "mobile",
  Stationary = "stationary"
}

export enum FixationLocation {
  Entry = "entry",
  Exit = "exit"
}

export interface Complex {
  uuid?: string;
  model?: string;
  type?: ComplexType;
  fixationLocation?: FixationLocation;
  pztCamera?: boolean;
  certificateNumber?: string;
  certificateExpiry?: string;
}
