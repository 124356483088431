import { Control, UseFormWatch } from "react-hook-form";
import { ComplexForm } from "./types";
import { FormControl, MenuItem, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import Select from "ui-kit/form/Select";
import { ComplexType, FixationLocation } from "hooks/queries/complexes";

interface FixationLocationSelectProps {
  control: Control<ComplexForm>;
  watch: UseFormWatch<ComplexForm>;
  fullWidth?: boolean;
  sx?: SxProps;
}

const FixationLocationSelect = ({ control, fullWidth, watch, sx }: FixationLocationSelectProps) => {
  const { t } = useTranslation();

  const complexType = watch("type");
  const isMobile = complexType === ComplexType.Mobile;

  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      <Select
        control={control}
        name='fixationLocation'
        label={t("pages.Complexes.labels.fixation_location")}
        disabled={isMobile}
        rules={{
          required: {
            value: !isMobile,
            message: t("common.input_rules.required")
          }
        }}
      >
        <MenuItem value={FixationLocation.Entry}>
          {t("pages.Complexes.fixation_locations.entry")}
        </MenuItem>
        <MenuItem value={FixationLocation.Exit}>
          {t("pages.Complexes.fixation_locations.exit")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default FixationLocationSelect;
