import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Control, UseFormSetValue } from "react-hook-form";
import { TabNames } from "../..";
import { UserEvent } from "api/generated";
import { formatUtcDate } from "utils/date";
import { TableBodyCell, TableHeaderCell } from "../styled";
import { useTranslation } from "react-i18next";
import Pagination from "components/Pagination";

export type Props = {
  control: Control<{
    offset?: number;
    limit?: number;
  }>;
  events: UserEvent[];
  setValue: UseFormSetValue<{
    offset?: number;
    limit?: number;
  }>;
  activeTab: TabNames;
  total: number;
};

const amountFormatter = new Intl.NumberFormat("ru-KZ", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});

const getAmountCellContent = ({ sign, amount }: UserEvent): string => {
  const formattedAmount = amount === undefined ? "" : amountFormatter.format(amount / 100);
  return amount === 0 ? formattedAmount : `${sign ?? ""}${formattedAmount}`;
};

const EventsTab = ({ events, activeTab, control, ...paginationProps }: Props) => {
  const { t } = useTranslation();
  if (activeTab !== TabNames.Events) return null;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>{t("pages.Accounts.item.event_list.date")}</TableHeaderCell>
              <TableHeaderCell>{t("pages.Accounts.item.event_list.title")}</TableHeaderCell>
              <TableHeaderCell>{t("pages.Accounts.item.event_list.amount")}</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events?.map((event) => (
              <TableRow key={event.uuid}>
                <TableBodyCell sx={{ padding: 1 }}>
                  <Box textAlign='center'>
                    {event.date && formatUtcDate(new Date(+(event.date || 0)), "dd.MM.yyyy")}
                  </Box>
                </TableBodyCell>
                <TableBodyCell>{event.title}</TableBodyCell>
                <TableBodyCell>{getAmountCellContent(event)}</TableBodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination control={control} {...paginationProps} />
    </>
  );
};

export default EventsTab;
