import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Complex, ComplexType, FixationLocation } from "hooks/queries/complexes";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { parseApiDate } from "../utils";

interface ComplexesTableProps {
  rows: Complex[];
  onEdit?: (complex: Complex) => void;
  onDelete?: (complex: Complex) => void;
}

const ComplexesTable = ({ rows, onEdit, onDelete }: ComplexesTableProps) => {
  const { t } = useTranslation();

  const getTypeText = (type?: ComplexType) => {
    switch (type) {
      case ComplexType.Stationary:
        return t("pages.Complexes.complex_types.stationary");

      case ComplexType.Mobile:
        return t("pages.Complexes.complex_types.mobile");

      default:
        return "-";
    }
  };

  const getFixationLocationText = (fixationLocation?: FixationLocation) => {
    switch (fixationLocation) {
      case FixationLocation.Entry:
        return t("pages.Complexes.fixation_locations.entry");

      case FixationLocation.Exit:
        return t("pages.Complexes.fixation_locations.exit");

      default:
        return "-";
    }
  };

  const getPztCameraText = (pztCamera?: boolean) => {
    switch (pztCamera) {
      case true:
        return t("pages.Complexes.pzt_cameras.true");

      case false:
        return t("pages.Complexes.pzt_cameras.false");

      default:
        return "-";
    }
  };

  const getCertificateExpiryText = (certificateExpiry?: string) => {
    const date = parseApiDate(certificateExpiry);

    return date ? date.toFormat("dd.MM.yyyy") : "-";
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("pages.Complexes.labels.id")}</TableCell>
          <TableCell>{t("pages.Complexes.labels.model")}</TableCell>
          <TableCell>{t("pages.Complexes.labels.type")}</TableCell>
          <TableCell>{t("pages.Complexes.labels.fixation_location")}</TableCell>
          <TableCell>{t("pages.Complexes.labels.pzt_camera")}</TableCell>
          <TableCell>{t("pages.Complexes.labels.certificate_number")}</TableCell>
          <TableCell>{t("pages.Complexes.labels.certificate_expiry")}</TableCell>
          <TableCell>{t("pages.Complexes.labels.management")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.uuid}>
            <TableCell>{row.uuid}</TableCell>
            <TableCell>{row.model}</TableCell>
            <TableCell>{getTypeText(row.type)}</TableCell>
            <TableCell>{getFixationLocationText(row.fixationLocation)}</TableCell>
            <TableCell>{getPztCameraText(row.pztCamera)}</TableCell>
            <TableCell>{row.certificateNumber}</TableCell>
            <TableCell>{getCertificateExpiryText(row.certificateExpiry)}</TableCell>
            <TableCell>
              <Box display='flex' gap='8px'>
                <IconButton onClick={onEdit && (() => onEdit(row))}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={onDelete && (() => onDelete(row))}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ComplexesTable;
