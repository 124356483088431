import { Box, Divider } from "@mui/material";
import { ParkingFixation } from "api/generated";
import FixationItem from "./FixationItem";
import React from "react";

interface FixationsListProps {
  fixations: ParkingFixation[];
}

const FixationsList = ({ fixations }: FixationsListProps) => {
  return (
    <Box display='flex' flexDirection='column' gap='32px'>
      {fixations.map((fixation, index) => (
        <React.Fragment key={index}>
          {index > 0 && <Divider />}
          <FixationItem fixation={fixation} />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default FixationsList;
