import { DateTime } from "luxon";
import { PztCamera } from "./ComplexDialog/types";

export const parseApiDate = (dateString?: string) => {
  const dateNumber = dateString ? parseInt(dateString) : NaN;

  if (isNaN(dateNumber) || dateNumber < 0) {
    return null;
  }

  return DateTime.fromMillis(dateNumber, { zone: "Asia/Almaty" });
};

export const getApiCertificateExpiry = (date?: DateTime | null): string | undefined =>
  date
    ?.setZone("Asia/Almaty")
    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    .valueOf()
    .toString();

export const parseSelectToApi = <T>(select: T | ""): T | undefined =>
  select === "" ? undefined : select;

export const parsePztCameraToApi = (pztCamera: PztCamera | ""): boolean | undefined => {
  switch (pztCamera) {
    case "":
      return undefined;

    case PztCamera.False:
      return false;

    case PztCamera.True:
      return true;
  }
};
