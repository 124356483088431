import { DateTime } from "luxon";

export const parseApiDate = (dateString?: string) => {
  const dateNumber = dateString ? parseInt(dateString) : NaN;

  if (isNaN(dateNumber) || dateNumber < 0) {
    return null;
  }

  return DateTime.fromMillis(dateNumber, { zone: "Asia/Almaty" });
};
