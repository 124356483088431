import { ComplexType, FixationLocation } from "hooks/queries/complexes";
import { DateTime } from "luxon";

export enum PztCamera {
  True = "true",
  False = "false"
}

export interface ComplexForm {
  uuid?: string;
  model: string;
  type: ComplexType | "";
  fixationLocation: FixationLocation | "";
  pztCamera: PztCamera | "";
  certificateNumber: string;
  certificateExpiry: DateTime | null;
}
