import { UseQueryOptions, UseQueryResult, useQuery } from "@tanstack/react-query";
import { requests } from "api";
import { GetSingleTariffResponse, RequestParams, Tariff } from "api/generated";
import { TARIFF_QUERY_KEY } from ".";
import { GET_QUERY_KEY } from "../action-keys";

const initialTariff: GetSingleTariffResponse = { tariff: {} };

export const useGetTariffQuery = (
  uuid: string,
  params: RequestParams,
  options?: Omit<UseQueryOptions, "queryKey" | "queryFn" | "initialData">
) => {
  const {
    data = initialTariff,
    isLoading,
    ...rest
  } = useQuery(
    [TARIFF_QUERY_KEY, GET_QUERY_KEY, uuid],
    () => requests.adminGatewayTariff(uuid, params).then(({ data }) => data),
    options
  );

  return {
    data,
    isLoading,
    ...rest
  } as UseQueryResult<GetSingleTariffResponse> & { data: Tariff };
};
