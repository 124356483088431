import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Complex, useDeleteComplexMutation } from "hooks/queries/complexes";
import { useTranslation } from "react-i18next";

interface DeleteComplexDialogProps {
  open: boolean;
  complex: Complex;
  onClose?: VoidFunction;
  TransitionProps?: TransitionProps;
}

const DeleteComplexDialog = ({ complex, onClose, open }: DeleteComplexDialogProps) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useDeleteComplexMutation();

  const handleSubmit = () => {
    mutate(complex, { onSuccess: () => onClose?.() });
  };

  return (
    <Dialog
      open={open}
      onClose={isLoading ? undefined : onClose}
      PaperProps={{
        sx: { width: "100%", maxWidth: "600px" }
      }}
    >
      <DialogTitle>{t("pages.Complexes.delete_complex")}</DialogTitle>
      <DialogContent>
        {t("pages.Complexes.confirm_delete_complex", { id: complex.uuid })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant='contained'>
          {t("common.delete")}
        </Button>
        <Button onClick={onClose} variant='outlined'>
          {t("common.cancel")}
        </Button>
      </DialogActions>

      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </Dialog>
  );
};

export default DeleteComplexDialog;
