import { Control } from "react-hook-form";
import { ComplexForm } from "./types";
import Input from "ui-kit/form/Input";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/material";

interface IdInputProps {
  control: Control<ComplexForm>;
  fullWidth?: boolean;
  sx?: SxProps;
}

const IdInput = (props: IdInputProps) => {
  const { t } = useTranslation();

  return (
    <Input
      {...props}
      label={t("pages.Complexes.labels.id")}
      name='uuid'
      InputProps={{
        disabled: true
      }}
    />
  );
};

export default IdInput;
