import { Backdrop, Box, CircularProgress } from "@mui/material";
import SearchBar from "./SearchBar";
import { useForm } from "react-hook-form";
import { useState } from "react";
import useDebouncedCallback from "hooks/useDebouncedCallback";
import {
  useIllegalSessionsQuery,
  GetIllegalSessionsRequest,
  IllegalSession,
  useSendIllegalSessionMutation
} from "hooks/queries/illegal-sessions";
import IllegalSessionsTableContainer from "./IllegalSessionsTableContainer";
import SendIllegalSessionDialog from "../SendIllegalSessionDialog";
import IllegalSessionDetailsDialog from "../IllegalSessionDetailsDialog";
import { useAppAbility } from "context/AbilityContext";

interface SendIllegalSessionDialogState {
  type: "send";
  open: boolean;
  illegalSession: IllegalSession;
}

interface IllegalSessionDetailsDialogState {
  type: "details";
  open: boolean;
  illegalSessionUuid: string;
}

const IllegalSessionsList = () => {
  const { can } = useAppAbility();
  const canUpdate = can("update", "IllegalSessions");

  const { control, setValue, watch } = useForm<GetIllegalSessionsRequest>({
    defaultValues: {
      q: "",
      limit: 20,
      offset: 0
    }
  });

  const [query, setQuery] = useState<string>("");

  const doSearch = useDebouncedCallback((value: string) => {
    setQuery(value);
    setValue("offset", 0);
  }, 300);

  const handleSearch = (value: string) => {
    setValue("q", value);
    doSearch(value);
  };

  const limit = watch("limit") || 0;
  const offset = watch("offset") || 0;

  const { data, isLoading } = useIllegalSessionsQuery({
    q: query.length >= 3 ? query : "",
    limit,
    offset
  });

  const [dialog, setDialog] = useState<
    SendIllegalSessionDialogState | IllegalSessionDetailsDialogState | null
  >(null);

  const { mutate, isLoading: isSending } = useSendIllegalSessionMutation();

  const handleTableSend = (illegalSession: IllegalSession) => {
    if (!canUpdate) {
      return;
    }

    if (illegalSession.sentToErap) {
      setDialog({
        type: "send",
        open: true,
        illegalSession
      });
    } else {
      mutate(illegalSession);
    }
  };

  const handleTableRowClick = ({ uuid }: IllegalSession) => {
    if (typeof uuid === "string") {
      setDialog({
        type: "details",
        open: true,
        illegalSessionUuid: uuid
      });
    }
  };

  const handleDialogSend = () => {
    if (dialog?.type !== "send" || !canUpdate) return;

    setDialog({ ...dialog, open: false });
    mutate(dialog.illegalSession);
  };

  return (
    <Box>
      <SearchBar control={control} onSearch={handleSearch} />

      {isLoading ? (
        <CircularProgress size={26} sx={{ mt: 2 }} />
      ) : (
        <IllegalSessionsTableContainer
          rows={data?.illegalSessions}
          total={data?.total ?? 0}
          control={control}
          setValue={setValue}
          onSend={handleTableSend}
          onRowClick={handleTableRowClick}
        />
      )}

      {dialog?.type === "send" && canUpdate && (
        <SendIllegalSessionDialog
          open={dialog.open}
          TransitionProps={{ onExited: () => setDialog(null) }}
          onClose={() => setDialog({ ...dialog, open: false })}
          onSend={handleDialogSend}
        />
      )}

      {dialog?.type === "details" && (
        <IllegalSessionDetailsDialog
          open={dialog.open}
          illegalSessionUuid={dialog.illegalSessionUuid}
          TransitionProps={{ onExited: () => setDialog(null) }}
          onClose={() => setDialog({ ...dialog, open: false })}
        />
      )}

      <Backdrop open={isSending}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default IllegalSessionsList;
