import { useQuery } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";
import { GetIllegalSessionsRequest, IllegalSession } from "./types";
import { requests } from "api";
import { IllegalSession as IllegalSessionApi } from "api/generated";

export const ILLEGAL_SESSIONS_QUERY_KEY = "illegal_sessions";

const mapIllegalSessionFromApi = ({
  carno,
  isSend,
  startTime,
  uuid,
  zone
}: IllegalSessionApi): IllegalSession => ({
  date: startTime,
  sentToErap: isSend,
  vrp: carno,
  uuid,
  zone
});

const getIllegalSessions = async ({ limit, offset, q }: GetIllegalSessionsRequest) => {
  const response = await requests.adminGatewayGetIllegalSessions({
    page: typeof offset === "number" ? offset + 1 : offset,
    pageSize: limit,
    query: q
  });

  return {
    illegalSessions: response.data.rec?.map(mapIllegalSessionFromApi),
    total: response.data.total
  };
};

const useIllegalSessionsQuery = (params: GetIllegalSessionsRequest) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery([ILLEGAL_SESSIONS_QUERY_KEY, params], () => getIllegalSessions(params), {
    onError() {
      openErrorAlert(t("pages.IllegalSessions.could_not_load_illegal_sessions"));
    },
    refetchOnWindowFocus: false
  });
};

export default useIllegalSessionsQuery;
