import { Box, Paper, TableContainer, Typography } from "@mui/material";
import Pagination from "components/Pagination";
import { Complex, ComplexesListParams } from "hooks/queries/complexes";
import { Control, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ComplexesTable from "./ComplexesTable";

interface ComplexesTableContainerProps {
  rows?: Complex[];
  control: Control<ComplexesListParams>;
  total: number;
  setValue: UseFormSetValue<ComplexesListParams>;
  onEdit?: (complex: Complex) => void;
  onDelete?: (complex: Complex) => void;
}

const ComplexesTableContainer = ({
  rows,
  control,
  total,
  setValue,
  onEdit,
  onDelete
}: ComplexesTableContainerProps) => {
  const { t } = useTranslation();

  if (!rows || rows.length === 0) {
    return (
      <Typography variant='h5' component='p'>
        {t("pages.Complexes.no_data")}
      </Typography>
    );
  }

  return (
    <>
      <Box display='grid'>
        <TableContainer
          component={Paper}
          sx={{ ".MuiTableCell-root.MuiTableCell-head": { fontWeight: "bold" } }}
        >
          <ComplexesTable rows={rows} onEdit={onEdit} onDelete={onDelete} />
        </TableContainer>
      </Box>

      <Pagination control={control} total={total} setValue={setValue} />
    </>
  );
};

export default ComplexesTableContainer;
