import { useMutation, useQueryClient } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { ILLEGAL_SESSIONS_QUERY_KEY } from "./useIllegalSessionQuery";
import useShowBackendErrors from "hooks/useShowBackendErrors";
import { useTranslation } from "react-i18next";
import { IllegalSession } from "./types";
import { requests } from "api";

const useSendIllegalSessionMutation = () => {
  const { t } = useTranslation();
  const { openSuccessAlert } = useAlertStatus();
  const showErrors = useShowBackendErrors();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ uuid }: IllegalSession) => requests.adminGatewaySendIllegalSessions({ uuid }),
    onSuccess(_, illegalSession) {
      openSuccessAlert(t("pages.IllegalSessions.session_sent", { vrp: illegalSession.vrp }));
      queryClient.invalidateQueries([ILLEGAL_SESSIONS_QUERY_KEY]);
    },
    onError(_, illegalSession) {
      showErrors({
        defaultMessage: t("pages.IllegalSessions.could_not_send_session", {
          vrp: illegalSession.vrp
        })
      });
    }
  });
};

export default useSendIllegalSessionMutation;
