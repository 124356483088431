import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IllegalSession } from "hooks/queries/illegal-sessions";
import { useTranslation } from "react-i18next";
import { parseApiDate } from "../utils";
import { MouseEventHandler } from "react";

interface IllegalSessionsTableProps {
  rows: IllegalSession[];
  onSend?: (illegalSession: IllegalSession) => void;
  onRowClick?: (illegalSession: IllegalSession) => void;
}

const IllegalSessionsTable = ({ rows, onSend, onRowClick }: IllegalSessionsTableProps) => {
  const { t } = useTranslation();

  const getDateCellContent = (dateString?: string) => {
    const date = parseApiDate(dateString);
    return date ? date.toFormat("dd.MM.yyyy") : "";
  };

  const getSentToErapCellContent = (sentToErap?: boolean) =>
    sentToErap
      ? t("pages.IllegalSessions.sent_to_erap.yes")
      : t("pages.IllegalSessions.sent_to_erap.no");

  const handleSendClick =
    (illegalSession: IllegalSession): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();
      onSend?.(illegalSession);
    };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t("pages.IllegalSessions.labels.vrp")}</TableCell>
          <TableCell>{t("pages.IllegalSessions.labels.date")}</TableCell>
          <TableCell>{t("pages.IllegalSessions.labels.zone")}</TableCell>
          <TableCell>{t("pages.IllegalSessions.labels.sent_to_erap")}</TableCell>
          <TableCell>{t("pages.IllegalSessions.labels.management")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.uuid}
            hover
            sx={{ cursor: "pointer" }}
            onClick={() => onRowClick?.(row)}
          >
            <TableCell>{row.vrp}</TableCell>
            <TableCell>{getDateCellContent(row.date)}</TableCell>
            <TableCell>{row.zone}</TableCell>
            <TableCell>{getSentToErapCellContent(row.sentToErap)}</TableCell>
            <TableCell>
              <Button variant='contained' onClick={handleSendClick(row)}>
                {t("pages.IllegalSessions.send")}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default IllegalSessionsTable;
