import { Control } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import SearchInput from "ui-kit/form/SearchInput";
import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetIllegalSessionsRequest } from "hooks/queries/illegal-sessions";

interface SearchType {
  onSearch: (value: string) => void;
  control: Control<GetIllegalSessionsRequest>;
}

const SearchBar = ({ onSearch, control }: SearchType) => {
  const { t } = useTranslation();

  const handleSearch: TextFieldProps["onChange"] = (e) => {
    onSearch(e.target.value);
  };

  return (
    <FormControl sx={{ width: "100%", maxWidth: "400px" }}>
      <SearchInput
        sx={{ flex: 1 }}
        onChange={handleSearch}
        label={t("pages.IllegalSessions.search")}
        control={control}
        name='q'
      />
    </FormControl>
  );
};

export default SearchBar;
