import { TableBody, TableCell, TableRow } from "@mui/material";
import { Sum } from "api/generated";
import { formatAmountForTable } from "pages/Reports/utils";

export interface SumReportTableBodyProps {
  rows?: Sum[];
  getTableRowNumber?: (index: number) => number;
}

const SumReportTableBody = ({
  rows,
  getTableRowNumber = (index) => index + 1
}: SumReportTableBodyProps) => {
  return (
    <TableBody>
      {rows?.map(({ name, totalSum }, index) => (
        <TableRow key={name}>
          <TableCell>{getTableRowNumber(index)}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{formatAmountForTable(totalSum ?? 0)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default SumReportTableBody;
