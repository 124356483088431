import { useQuery } from "@tanstack/react-query";
import useAlertStatus from "components/StatusAlert/hooks/useAlertStatus";
import { useTranslation } from "react-i18next";
import { Complex, ComplexesListParams, ComplexType, FixationLocation } from "./types";
import { requests } from "api";
import { Complex as ComplexAPI } from "api/generated";

export const COMPLEXES_QUERY_KEY = "complexes";

const mapApiFixationLocation = (fix?: string): FixationLocation | undefined => {
  if (fix === FixationLocation.Entry || fix === FixationLocation.Exit) {
    return fix;
  }
};

const mapApiComplexType = (complexType?: string): ComplexType | undefined => {
  if (complexType === ComplexType.Mobile || complexType === ComplexType.Stationary) {
    return complexType;
  }
};

const mapApiComplex = (complex: ComplexAPI): Complex => ({
  uuid: complex.complexId,
  certificateExpiry: complex.complexExpirationDate,
  certificateNumber: complex.complexCertificateMetrology,
  fixationLocation: mapApiFixationLocation(complex.fix),
  model: complex.complexModel,
  pztCamera: complex.pzt,
  type: mapApiComplexType(complex.complexType)
});

const getComplexes = async ({ limit, offset, q }: ComplexesListParams) => {
  const parsedComplexId = q ? parseInt(q) : NaN;

  const complexesResponse = await requests.adminGatewayGetComplexes({
    complexId: isNaN(parsedComplexId) ? undefined : parsedComplexId.toString(),
    page: typeof offset === "number" ? offset + 1 : offset,
    pageSize: limit
  });

  const complexes = complexesResponse.data.complexes?.map(mapApiComplex);

  return {
    complexes,
    total: complexesResponse.data.total
  };
};

const useComplexesQuery = (params: ComplexesListParams) => {
  const { t } = useTranslation();
  const { openErrorAlert } = useAlertStatus();

  return useQuery([COMPLEXES_QUERY_KEY, params], () => getComplexes(params), {
    onError() {
      openErrorAlert(t("pages.Complexes.could_not_load_complexes"));
    },
    refetchOnWindowFocus: false
  });
};

export default useComplexesQuery;
