import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { useIllegalSessionDetailsQuery } from "hooks/queries/illegal-sessions";
import { useTranslation } from "react-i18next";
import FixationsList from "./FixationsList";

interface IllegalSessionDetailsDialogProps {
  open: boolean;
  illegalSessionUuid: string;
  onClose?: VoidFunction;
  TransitionProps?: TransitionProps;
}

const IllegalSessionDetailsDialog = ({
  open,
  illegalSessionUuid,
  onClose,
  TransitionProps
}: IllegalSessionDetailsDialogProps) => {
  const { t } = useTranslation();

  const { isLoading, data } = useIllegalSessionDetailsQuery(illegalSessionUuid);
  const fixations = data?.data.rec?.fixations;

  return (
    <Dialog
      open={open}
      TransitionProps={TransitionProps}
      onClose={onClose}
      PaperProps={{ sx: { width: "660px", maxWidth: "100%" } }}
    >
      <DialogTitle>{t("pages.IllegalSessions.illegal_session_details")}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : fixations && fixations.length > 0 ? (
          <FixationsList fixations={fixations} />
        ) : (
          <Typography variant='h6' component='p' textAlign='center'>
            {t("pages.IllegalSessions.no_data")}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default IllegalSessionDetailsDialog;
