import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DataPicker from "ui-kit/form/DataPicker";
import { FormControl, SxProps } from "@mui/material";
import { ComplexForm } from "./types";
import { DateTime } from "luxon";

interface CertificateExpiryDatePickerProps {
  control: Control<ComplexForm>;
  fullWidth?: boolean;
  sx?: SxProps;
}

const CertificateExpiryDatePicker = ({
  control,
  fullWidth,
  sx
}: CertificateExpiryDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth={fullWidth} sx={sx}>
      <DataPicker
        rules={{
          required: {
            value: true,
            message: t("common.input_rules.required")
          },
          validate: (value: DateTime | null) => {
            if (value && !value.isValid) return t("common.input_rules.invalid_date");
          }
        }}
        inputFormat='dd.MM.yyyy'
        control={control}
        name='certificateExpiry'
        label={t("pages.Complexes.labels.certificate_expiry")}
      />
    </FormControl>
  );
};

export default CertificateExpiryDatePicker;
