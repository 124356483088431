import { createTheme, Theme } from "@mui/material";

export const createComplexDialogTheme = (baseTheme: Theme) =>
  createTheme({
    ...baseTheme,
    components: {
      ...baseTheme.components,
      MuiFormHelperText: {
        ...baseTheme.components?.MuiFormHelperText,
        styleOverrides: {
          root: {
            marginLeft: 0
          }
        }
      },
      MuiFormLabel: {
        ...baseTheme.components?.MuiFormLabel,
        styleOverrides: {
          root: {
            "&.Mui-focused, &.Mui-error": {
              color: baseTheme.palette.text.secondary
            }
          }
        }
      }
    }
  });
