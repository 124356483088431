import { Box, Typography } from "@mui/material";
import { ParkingFixation } from "api/generated";

interface FixationItemProps {
  fixation: ParkingFixation;
}

const FixationItem = ({ fixation }: FixationItemProps) => {
  const { images, ...fixationToShow } = fixation;
  const { carImage, plateImage } = images || {};

  return (
    <Box display='flex' gap='8px' flexDirection='column'>
      <Typography whiteSpace='pre-wrap'>{JSON.stringify(fixationToShow, null, 4)}</Typography>
      <Box component='img' src={`data:image/jpeg;base64,${carImage}`} width='100%' height='auto' />
      <Box
        component='img'
        src={`data:image/jpeg;base64,${plateImage}`}
        width='100%'
        height='auto'
      />
    </Box>
  );
};

export default FixationItem;
